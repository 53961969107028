import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { navigate } from 'gatsby-link'
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/BlogPost/styled"

const BlogPost = ({ data }) => {
    const blogPost = data.markdownRemark
    const recentPosts = data.postRecentBlogList

    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        message: '',
      })

    const encode = (data) => {
        return Object.keys(data)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          .join('&')
      }  


    const handleChange = (event) => {
        const { name, value } = event.target

        setFormData({ ...formData, [name]: value })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target

        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...formData,
          }),
        })
          .then(() => navigate(form.getAttribute('action')))
          .catch((error) => alert(error))
      }

    return (
        <Layout title="Blog">
            <SEO title={ blogPost.frontmatter.title } description={ blogPost.frontmatter.description } image={ blogPost.frontmatter.img } />
            <S.BlogMainContainer>
                <S.BlogPosts>
                    <S.PostHeader>  
                        <S.PostTitle>{ blogPost.frontmatter.title }</S.PostTitle>
                        <S.PostDate>{ blogPost.frontmatter.date }</S.PostDate>
                    </S.PostHeader>
                    <S.MainContent>
                        <div dangerouslySetInnerHTML={{ __html: blogPost.html }}></div>
                    </S.MainContent>
                </S.BlogPosts>
                <S.BlogSidebar>
                <S.FormContainer>
                        <h6>Cadastre-se e receba novidades da sala de aula virtual:</h6>
                        <form 
                            name="sala-de-aula-virtual"
                            method="POST"
                            action="/obrigado"
                            data-netlify="true"
                            onSubmit={handleSubmit}
                            >
                            <S.Field>
                                <label htmlFor="name" >Digite seu nome*</label>
                                <input type="text" name="name" id="name" required={true}
                                    onChange={handleChange} />
                            </S.Field>
                            <S.Field>
                                <label htmlFor="email" >Digite seu e-mail*</label>
                                <input type="email" name="email" id="email" required={true}
                                    onChange={handleChange} />
                            </S.Field>
                            <S.Field>
                                <button type="submit">Cadastrar</button>
                            </S.Field>
                        </form>
                    </S.FormContainer>
                    <hr />
                    <S.RecentPostsContainer>
                        <h6>Últimos posts</h6>
                        <S.RecentPosts>
                            {
                                recentPosts.edges.map(({ node: { id, fields: { slug }, frontmatter: { date, title, img } } }) => {
                                    return (
                                        <S.RecentPost key={id}>
                                            <S.Img>
                                                <Img fluid={img.childImageSharp.fluid}  />
                                            </S.Img>
                                            <S.RecentPostInfos>
                                                <Link to={`/blog${slug}`}><h2>{title}</h2></Link>
                                                <span>{date}</span>
                                            </S.RecentPostInfos>
                                        </S.RecentPost>
                                    )
                                })
                            }
                        </S.RecentPosts>
                    </S.RecentPostsContainer>
                </S.BlogSidebar>
            </S.BlogMainContainer>
        </Layout>
    )
}

export const query = graphql`
    query Blog($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
            }
            html
        }
        postRecentBlogList: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(blog)/"}}, sort: {fields: frontmatter___date, order: DESC}, limit: 5) {
                        edges {
                        node {
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
                                description
                                autor
                                title
                                img {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    }
`

export default BlogPost