import styled from "styled-components"
import media from 'styled-media-query'

export const BlogMainContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    grid-gap: 80px;
    max-width: 90%;
    padding: 10px;
    margin: 0 auto;

    ${media.lessThan("medium")`
        grid-template-columns: 1fr;        
    `}
`

export const BlogPosts = styled.div``

export const BlogPost = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    div {
       display: block;
       width: 100%;
       height: 300px;
       border-radius: 20px;
       background-color: #29559C
    }

    h2 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        color: #29559C;

        text-transform: uppercase;

        margin: 20px 0 5px 0;
    }

    h4 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.15rem;
        font-weight: 700;
        color: #525252;
        opacity: 0.8;

        text-transform: uppercase;

        margin-bottom: 20px; 
    }

    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 0.9em;
        font-weight: 700;
        color: #969696;

        line-height: 22px
    }

    span {
        font-family: 'Montserrat', sans-serif;
        font-size: 0.9em;
        font-weight: 700;
        color: #fff;

        padding: 15px 30px;
        background: transparent linear-gradient(180deg, #37A19B 0%, #416DB2 100%) 0% 0% no-repeat padding-box;
        border-radius: 24px;

        margin: 40px 0;

        transition: all 0.45s ease;

        a {
            color: #fff;
        }

        cursor: pointer;

        &:hover{
            transform: scale(1.05);
        }    
    }

`

export const BlogSidebar = styled.div`

    hr {
        margin-top: 30px;
        margin-bottom: 20px;
        border: none;
        height: 0.5px;
        background-color: #ACACAC;
        
    }
`

export const FormContainer = styled.div`
    background: transparent linear-gradient(180deg, #4876B5 0%, #243B5B 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px;
    padding: 40px 30px 30px 30px;

    h6 {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.1em;
        font-weight: 700;
        color: #fff;

        line-height: 30px;

        margin-bottom: 30px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`

export const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 100%;

    margin-bottom: 20px;

    label {
        font-family: 'Montserrat', sans-serif;
        font-size: 1em;
        font-weight: 700;
        color: #fff;

        margin-bottom: 10px;

    }

    input {
        width: 90%;

        align-self: center;

        padding: 10px 20px;
        border: none;
        border-radius: 24px;

        font-family: 'Montserrat', sans-serif;
        font-size: 1em;
        font-weight: 700;
        color: #303030;b

        &:focus {
            border: none;
            outline: none!important;
        }
    }

    button {
        width: 100%;
        padding: 10px 20px;

        background: #38989E 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000052;

        font-family: 'Montserrat', sans-serif;
        font-size: 1.1em;
        font-weight: 900;
        color: #fff;

        border: none;
        border-radius: 24px;

        margin-top: 20px;

        transition: all 0.45s ease;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:hover {
            transform: scale(1.02)
        }
    }

`



export const RecentPosts = styled.div`
`

export const RecentPostsContainer = styled.div`
    h6 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        color: #36A09A;

        margin: 50px 0px;
    }
`

export const RecentPost = styled.div`
    display: flex;
    flex-direction: row;

    height: 170px;

    margin-bottom: 40px;

    ${media.lessThan("large")`
        flex-direction: column;
        height: 100%;

    `}
`

export const Img = styled.div`
    display: block;
    width: 40%;
    height: 100px;
    border-radius: 38px;
    background-color: #29559C;
    background-image: url(${ props => props.img }); 
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    background-position: center center;

    ${media.lessThan("large")`
      width: 100%;
      height: 200px;
      margin-bottom: 10px;
    `}    
`

export const RecentPostInfos = styled.div`

    width: 60%;

    margin-left: 10px;

    h2 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 2rem;
        font-weight: 700;
        color: #29559C;
        text-transform: uppercase;

        overflow: hidden;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        margin-bottom: 10px;

        transition: all 0.45s ease;

        cursor: pointer;

        &:hover{
            color: #36A09A;
        }
    }

    span {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.15rem;
        font-weight: 700;
        color: #525252;
        opacity: 0.8;

        text-transform: uppercase;
    }

    ${media.lessThan("large")`
        width: 100%;    
    `}
`

export const PostHeader = styled.header`
  padding: 5rem 5rem 0;
  font-family: 'Titillium Web', sans-serif;
  text-transform: uppercase;

  ${media.lessThan("large")`
    padding: 3rem 0 0;
    max-width: 100%;
  `}
`

export const PostTitle = styled.h1`
  color: #29559C;
  font-size: 2rem;
  font-weight: 700;
  padding: 0 1.4rem;
  margin: 0 auto;

  ${media.lessThan("large")`
    font-size: 1.5rem;
    line-height: 1.1;
    padding: 0 1rem;
  `}
`

export const PostDate = styled.p`
  font-size: 1.1rem;
  font-weight: 100;
  padding: 0 1.4rem;

  ${media.lessThan("large")`
    font-size: 1rem;
    padding: 0.3rem 1rem;
  `}
`

export const MainContent = styled.section`
  margin: auto;
  max-width: 70rem;
  padding: 2rem 5rem;

  ${media.lessThan("large")`
    padding: 2rem 0;
    max-width: 100%;
  `}

  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  .tags,
  iframe,
  .button-post {
    color: #969696;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.7;
    padding: 0 1.4rem;
    font-family: 'Montserrat', sans-serif;

    ${media.lessThan("large")`
      padding: 0 1rem;
      word-break: break-word;
    `}
  }
  p {
    margin: 0 auto 1.6rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 2.4rem auto 1rem;
  }
  ul,
  ol {
    list-style: disc;
    padding-left: 2.5rem;
    margin: 0 auto 1.6rem;
  }
  li {
    padding: 0.625rem 0;
    & > ul {
      margin-bottom: 0;
    }
  }
  p,
  li {
    code {
      word-wrap: break-word;
    }
  }
  img {
    display: block;
    max-width: 100%;
    margin: 1.875rem auto;
  }
  iframe {
    padding: 0 1.6rem 1.6rem;
    width: 100%;

    ${media.lessThan("large")`
      padding: 0 1rem;
    `}
  }
  blockquote {
    color: #fff;
    border-left: 0.3rem solid #1fa1f2;
    padding: 0 1.875rem;
    margin: 3.125rem auto;
  }
  hr {
    border: 1px solid #38444d;
    margin: 3rem auto;
  }
  #twitter-widget-0,
  .instagram-media,
  .twitter-tweet {
    margin: 20px auto !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
    line-height: 1.4;
    color: #3991A0;
    font-family: 'Titillium Web', sans-serif;
  }
  h1 {
    font-size: 2.8rem;

    ${media.lessThan("large")`
      font-size: 1.875rem;
    `}
  }
  h2 {
    font-size: 2.1rem;
    ${media.lessThan("large")`
      font-size: 1.375rem;
    `}
  }
  h3 {
    font-size: 1.6rem;
    ${media.lessThan("large")`
      font-size: 1.125rem;
    `}
  }
  h5 {
    font-size: 1.2rem;
  }
  strong {
    font-weight: 700;
  }
  .gatsby-resp-image-background-image {
    z-index: 2;
    opacity: 1 !important;
  }
  .gatsby-resp-image-image {
    box-shadow: none !important;
    transition: opacity 0.2s;
    &.lazyload {
      opacity: 0;
    }
    &.lazyloaded {
      opacity: 1;
      z-index: 3;
    }
  }
  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;

    ${media.lessThan("large")`
      padding: 0;
    `}
  }
  .instagram-media {
    margin: 1rem auto !important;
  }
  a {
    border-bottom: 1px dashed #1fa1f2;
    color: #1fa1f2;
    text-decoration: none;
    transition: opacity 0.5s;
    svg {
      color: #fff;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`